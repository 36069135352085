import React, { useCallback, useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { token } from '@atlaskit/tokens';

import {
	useSetOnboardingState,
	useGetOnboardingState,
} from '@confluence/onboarding-helpers/entry-points/hooks/useOnboardingState';
import { ONBOARDING_EXPERIENCES } from '@confluence/onboarding-helpers/entry-points/constants/onboarding-state-constants';
import { useChoreographerAssets } from '@confluence/choreographer-services';
import { fg } from '@confluence/feature-gating';
import { useObjectSidebarState } from '@confluence/object-sidebar-api';
import {
	isLiveDocChangeboardingActive,
	useLiveDocChangeboarding,
} from '@confluence/onboarding-live-doc-changeboarding/entry-points/useLiveDocChangeboarding';
import { useContentType } from '@confluence/page-context';
import { OperationTypes, useCanPerformContentOperation } from '@confluence/entity-operations';
import { useIsLicensed } from '@confluence/session-data';

import {
	IS_DISMISSED_FLAG,
	MessageType,
	READ_MORE_URL,
	REDACTIONS_TOUR_TARGETS,
} from '../redaction-constants';

import { useRedactionsTourState } from './useRedactionsTourState';

const { IS_REDACTIONS_TOUR_VISIBLE } = ONBOARDING_EXPERIENCES;

const i18n = defineMessages({
	stepOfSteps: {
		id: 'redactions.redactions-tour.steps',
		defaultMessage: '{step}/{steps}',
		description: 'A label that tracks which step of the tour the user is currently on',
	},
	dismiss: {
		id: 'redactions.redactions-tour.dismiss',
		defaultMessage: 'Dismiss',
		description: 'A label that indicates which button should be clicked to dismiss the tour',
	},
	next: {
		id: 'redactions.redactions-tour.next',
		defaultMessage: 'Next',
		description:
			'A label that indicates which button should be clicked to go to the next step of the tour',
	},
	ok: {
		id: 'redactions.redactions-tour.ok',
		defaultMessage: 'OK',
		description: 'A label that indicates which button should be clicked to end the tour',
	},
	readMore: {
		id: 'redactions.redactions-tour.read-more',
		defaultMessage: 'Read more',
		description: 'A label that indicates which button should be clicked to read more',
	},
	solidBarIndicates: {
		id: 'redactions.redactions-tour.solid-bar-indicates',
		defaultMessage:
			'The solid bar indicates that your organization detected and deleted sensitive data.',
		description: 'The text of a spotlight highlighting the nature of the redactions feature',
	},
	goToRedactions: {
		id: 'redactions.redactions-tour.go-to-redactions',
		defaultMessage:
			'Go to Redactions in this menu to view details about the redactions on this page.',
		description:
			'The text of a spotlight showing the user where they can see more about the redactions',
	},
});

type UseSpotlightsProps = {
	next: () => void;
	end: () => void;
	tourStartsInTitle?: boolean;
};

const useSpotlights = ({ next, end, tourStartsInTitle }: UseSpotlightsProps) => {
	const { formatMessage } = useIntl();

	const firstSpotlightKeyTarget = tourStartsInTitle
		? REDACTIONS_TOUR_TARGETS.TITLE_REDACTION
		: REDACTIONS_TOUR_TARGETS.REDACTION_EXTENSION;

	return [
		{
			key: firstSpotlightKeyTarget,
			target: firstSpotlightKeyTarget,
			messageType: MessageType.Engagement,
			actionsBeforeElement: formatMessage(i18n.stepOfSteps, { step: 1, steps: 2 }),
			targetRadius: 3,
			actions: [
				{ text: formatMessage(i18n.next), onClick: next },
				{ text: formatMessage(i18n.dismiss), onClick: end },
			],
			children: formatMessage(i18n.solidBarIndicates),
		},
		{
			key: REDACTIONS_TOUR_TARGETS.SIDEBAR_DETAILS_BUTTON,
			target: REDACTIONS_TOUR_TARGETS.SIDEBAR_DETAILS_BUTTON,
			messageType: MessageType.Transactional,
			actionsBeforeElement: formatMessage(i18n.stepOfSteps, { step: 2, steps: 2 }),
			targetRadius: 100,
			actions: [
				{ text: formatMessage(i18n.ok), onClick: end },
				{ text: formatMessage(i18n.readMore), href: READ_MORE_URL, target: '_blank' },
			],
			children: formatMessage(i18n.goToRedactions),
		},
	];
};

export enum RedactionTourStepSource {
	bodyRedaction = 'bodyRedaction',
	titleRedaction = 'titleRedaction',
}

type UseRedactionsTourProps = {
	contentId?: string | null;
	step: number;
	firstStepSource?: RedactionTourStepSource;
};

// NOTE: this tour also indirectly depends on fg('confluence_frontend_object_sidebar')
export const useRedactionsTour = ({
	contentId,
	step,
	firstStepSource = RedactionTourStepSource.bodyRedaction,
}: UseRedactionsTourProps) => {
	const { sidebarControlState } = useObjectSidebarState();
	const [
		{
			activeSpotlightIndex,
			lastActiveSpotlightIndex,
			firstRedactionIdRendered,
			titleRedactionRenderedForContentId,
		},
		{
			concludeTour: endTour,
			proceedToNext,
			initiateTour,
			resetTour,
			setFirstRedactionIdRendered,
			setTitleRedactionRenderedForContentId,
		},
	] = useRedactionsTourState();

	const isFirstTourTargetVisible =
		firstStepSource === RedactionTourStepSource.bodyRedaction
			? step === 0 && firstRedactionIdRendered && !titleRedactionRenderedForContentId
			: step === 0 && titleRedactionRenderedForContentId === contentId; // otherwise, it's a title redaction

	const isSecondTourTargetVisible = sidebarControlState === 'shown';

	const [contentType] = useContentType();
	const [canUpdateContent] = useCanPerformContentOperation({
		contentId: contentId ?? '',
		contentType,
		operation: OperationTypes.UPDATE,
	});
	const isLicensed = useIsLicensed();

	const liveDocChangeboarding = useLiveDocChangeboarding();
	const anotherTourTakesPrecedence = isLiveDocChangeboardingActive(liveDocChangeboarding);

	const { setOnboardingState } = useSetOnboardingState();
	const {
		data: onboardingStateData,
		error: onboardingStateError,
		loading: onboardingStateLoading,
	} = useGetOnboardingState(IS_REDACTIONS_TOUR_VISIBLE, !fg('dlp_cc-redactions-tour'));

	const isTourOnboardingStateCompleted = onboardingStateData?.onboardingState?.some(
		({ key, value }: { key: string; value: string | null }) => {
			return key === IS_REDACTIONS_TOUR_VISIBLE && value === IS_DISMISSED_FLAG;
		},
	);

	const isTourCompleted =
		!onboardingStateError && !onboardingStateLoading && isTourOnboardingStateCompleted;

	const { Spotlight } = useChoreographerAssets();

	const concludeTour = useCallback(() => {
		endTour();
		setOnboardingState({ key: IS_REDACTIONS_TOUR_VISIBLE, value: IS_DISMISSED_FLAG });
	}, [endTour, setOnboardingState]);

	useEffect(() => {
		if (
			isFirstTourTargetVisible &&
			isSecondTourTargetVisible &&
			!isTourCompleted &&
			!onboardingStateError &&
			!onboardingStateLoading &&
			!anotherTourTakesPrecedence &&
			isLicensed &&
			canUpdateContent &&
			fg('dlp_cc-redactions-tour')
		) {
			setTimeout(() => {
				initiateTour();
			}, 0);
		}
	}, [
		initiateTour,
		isFirstTourTargetVisible,
		isSecondTourTargetVisible,
		isTourCompleted,
		onboardingStateError,
		onboardingStateLoading,
		anotherTourTakesPrecedence,
		isLicensed,
		canUpdateContent,
	]);

	const spotlights = useSpotlights({
		next: proceedToNext,
		end: concludeTour,
		tourStartsInTitle: !!titleRedactionRenderedForContentId,
	});

	return {
		activeSpotlight:
			activeSpotlightIndex === step ? (
				<Spotlight
					dialogPlacement="bottom right"
					shouldWatchTarget // Note: problematic layout shifts for both spotlights
					targetBgColor={token('elevation.surface.raised')}
					{...spotlights[activeSpotlightIndex]}
				/>
			) : null,
		initiateTour,
		proceedToNext,
		concludeTour,
		resetTour,
		activeSpotlightIndex,
		lastActiveSpotlightIndex,
		isTourCompleted,
		setFirstRedactionIdRendered,
		setTitleRedactionRenderedForContentId, // Note: mainly here for testing purposes
	};
};
