import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-apollo';

import { isStatusCodeError } from '@confluence/error-types';
import { markErrorAsHandled } from '@confluence/graphql';
import { fg } from '@confluence/feature-gating';

import type {
	RedactionsCountQuery as RedactionsCountQueryType,
	RedactionsCountQueryVariables,
} from '../queries/__types__/RedactionsCountQuery';
import { RedactionsCountQuery } from '../queries/RedactionsCountQuery.graphql';

import { useRedactionsTourState } from './useRedactionsTourState';

export const TITLE_REDACTION_STRING = '████';

export const doesTitleContainRedactionString = (title?: string) => {
	if (!title) {
		return false;
	}
	return title.includes(TITLE_REDACTION_STRING);
};

type UseTrackTitleRedactionRenderedProps = {
	contentId: string;
	title?: string;
};

export const useTrackTitleRedactionRendered = ({
	contentId,
	title,
}: UseTrackTitleRedactionRenderedProps) => {
	const [_, { setTitleRedactionRenderedForContentId }] = useRedactionsTourState();

	const titleMaybeHasRedaction = useMemo(() => doesTitleContainRedactionString(title), [title]);

	const { data, error: redactionsCountError } = useQuery<
		RedactionsCountQueryType,
		RedactionsCountQueryVariables
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations
		RedactionsCountQuery,
		{
			skip: !titleMaybeHasRedaction || !fg('dlp_cc-redactions-tour'),
			variables: {
				contentId,
			},
			errorPolicy: 'all',
		},
	);

	// handle 4xx errors
	useEffect(() => {
		if (redactionsCountError && isStatusCodeError(redactionsCountError, '404')) {
			markErrorAsHandled(redactionsCountError);
		}
	}, [redactionsCountError]);

	const redactionsCount = data?.singleContent?.redactionMetadataCount ?? 0;

	useEffect(() => {
		if (contentId && titleMaybeHasRedaction && redactionsCount > 0) {
			setTitleRedactionRenderedForContentId(contentId);
		}
		return () => {
			// Cleanup function to reset the title redaction state
			setTitleRedactionRenderedForContentId(null);
		};
	}, [contentId, titleMaybeHasRedaction, redactionsCount, setTitleRedactionRenderedForContentId]);
};
