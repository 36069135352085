import { useEffect } from 'react';

import { useSessionData } from '@confluence/session-data';

import type { LoomEntryPointVariant } from './useLoomEntryPointVariantState';
import { useLoomEntryPointVariantState } from './useLoomEntryPointVariantState';

type UseLoomPermissionStatusOptions = {
	skip?: boolean;
};

/**
 * WARNING: This is meant to reflect Loom access status once M2.5 launches.
 *
 * For Beta, check `useIsLoomBetaEnabled` instead
 */
export const useLoomPermissionStatus = ({
	skip = false,
}: UseLoomPermissionStatusOptions = {}): LoomEntryPointVariant => {
	const { cloudId, userId, environment } = useSessionData();
	const [{ loomEntryPointVariant }, { initLoomEntryPointVariant }] =
		useLoomEntryPointVariantState();
	const { entryPointVariant, loading } = loomEntryPointVariant;

	useEffect(() => {
		if (skip) {
			return;
		}

		if (entryPointVariant === 'NONE' && !loading) {
			initLoomEntryPointVariant(cloudId, userId, environment);
		}
	}, [cloudId, initLoomEntryPointVariant, loading, entryPointVariant, skip, userId, environment]);

	if (entryPointVariant === 'NONE') {
		return { entryPointVariant: 'NONE', loading: true };
	}

	return loomEntryPointVariant;
};
