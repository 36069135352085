import React from 'react';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';

import { usePageContentId } from '@confluence/page-context';
import {
	TitleAlignmentType,
	useTitleContentPropertiesForPublishedPage,
} from '@confluence/content-topper';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div<{ isTitleCenterAligned?: boolean }>({
	margin: `${token('space.250')} 0 ${token('space.250')} 0`,
	height: '40px',
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	justifyContent: (p) => (p.isTitleCenterAligned ? 'center' : 'normal'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Circle = styled.div<{ isTitleCenterAligned?: boolean }>({
	backgroundColor: token('color.skeleton'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: (p) => (p.isTitleCenterAligned ? token('space.300') : token('space.400')),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: (p) => (p.isTitleCenterAligned ? token('space.300') : token('space.400')),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	margin: (p) => (p.isTitleCenterAligned ? '0px 7px 0px 0px' : '3px 0px 0px 0px'),
	borderRadius: '50%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LineContainer = styled.div({
	margin: '0 0',
	marginLeft: token('space.100'),
	marginRight: token('space.025'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Line1 = styled.div({
	backgroundColor: token('color.skeleton'),
	width: '100px',
	height: '14px',
	borderRadius: '2px',
	marginTop: token('space.050'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Line2 = styled.div<{ isTitleCenterAligned?: boolean }>({
	backgroundColor: token('color.skeleton'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: (p) => (p.isTitleCenterAligned ? '250px' : '150px'),
	height: '12px',
	borderRadius: '2px',
	marginTop: token('space.050'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FirstRowContainer = styled.div({
	display: 'flex',
	justifyContent: 'center',
});

export const ByLinePlaceholder = () => {
	const [contentId] = usePageContentId();

	const { titleContentProperties } = useTitleContentPropertiesForPublishedPage({
		contentId,
	});
	const isTitleCenterAligned =
		titleContentProperties?.titleLayoutAlignment == TitleAlignmentType.CENTER;

	if (isTitleCenterAligned) {
		return (
			<Container isTitleCenterAligned={isTitleCenterAligned}>
				<LineContainer>
					<FirstRowContainer>
						<Circle isTitleCenterAligned={isTitleCenterAligned} />
						<Line1 />
					</FirstRowContainer>
					<Line2 isTitleCenterAligned={isTitleCenterAligned} />
				</LineContainer>
			</Container>
		);
	}

	return (
		<Container>
			<Circle />
			<LineContainer>
				<Line1 />
				<Line2 />
			</LineContainer>
		</Container>
	);
};
