import { isFedRamp } from '@atlassian/atl-context';

import { isMobileUserAgent, shouldShowMobileWeb } from '@confluence/mobile-detection';
import { useSessionData, AccessStatus } from '@confluence/session-data';

/**
 * Checking for if Loom is disabled.
 * These are some SSR friendly checks that we can run before we even try to render
 * this entrypoint to try and prevent some page header shifting issues.
 */
export const useIsLoomDisabled = () => {
	const isInFedRamp = isFedRamp();
	const isMobile = isMobileUserAgent() || shouldShowMobileWeb();

	const { accessStatus, isLoggedIn } = useSessionData();
	const isLicensed =
		accessStatus == AccessStatus.LICENSED_ADMIN_ACCESS ||
		accessStatus == AccessStatus.LICENSED_USE_ACCESS;

	if (!isLoggedIn || !isLicensed) {
		return true;
	}

	if (isInFedRamp) {
		return true;
	}

	// don't enable entry points if user is on a mobile device or viewing mobile web
	if (isMobile) {
		return true;
	}

	return false;
};
