import { LoadableAfterPaint } from '@confluence/loadable';

export {
	useMoreActionsObjectSidebarTour,
	MoreActionsObjectSidebarTourSteps,
} from './useMoreActionsObjectSidebarTour';

export { useMoreActionsObjectSidebarTourActions } from './useMoreActionsObjectSidebarTourState';

export { OBJECT_SIDEBAR_TOUR_TARGETS } from './objectSidebarTourTargets';

export { ObjectSidebarPushPlaceholder } from './ObjectSidebarPushPlaceholder';

export const EditorObjectSidebarPanel = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-EditorObjectSidebarPanel" */ './EditorObjectSidebarPanel'
			)
		).EditorObjectSidebarPanel,
});

export const ObjectSidebarPanel = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ObjectSidebarPanel" */ './ObjectSidebarPanelWrapper'
			)
		).ObjectSidebarPanelWrapper,
});

export const ObjectSidebarControl = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-ObjectSidebarControl" */ './ObjectSidebarControl'))
			.ObjectSidebarControl,
});
