import { LoadablePaint } from '@confluence/loadable';

export const ContentTypesHeader = LoadablePaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-ContentTypesHeader" */ './ContentTypesHeader'))
			.ContentTypesHeader,
});

export type { HeaderFeaturesType } from './ContentTypesHeaderFeatures';
export type { MainActions } from './content-types-actions/MainActions';
export type { Collaborators } from './content-types-actions/Collaborators';
export type { MoreActions, Item } from './content-types-actions/MoreActions';
export type { ContentStatus } from './content-types-actions/ContentStatus/ContentStatus';
export type { ContentActions } from './ContentTypesHeader';
export { ContentStatusName } from './content-types-actions/ContentStatus/ContentStatus';
