export const ADMIN_ANNOUNCEMENT_BANNER_SETTING_PAGE_LOAD_KEY =
	'admin-announcement-banner-setting-page';
export const ADVANCED_SEARCH_LOAD_KEY = 'advanced-search';
export const UNIFIED_SEARCH_LOAD_KEY = 'unified-search';
export const CONFLUENCE_HOME_LOAD_KEY = 'confluence-home';
export const CONTENT_HISTORY_LOAD_KEY = 'content-history';
export const EDIT_PAGE_LOAD_KEY = 'edit-page';
export const EDIT_TEMPLATE_LOAD_KEY = 'edit-template';
export const EDIT_TEMPLATE_OLD_LOAD_KEY = 'edit-template-old';
export const EMBEDDED_VIEW_PAGE_LOAD_KEY = 'embedded-view-page';
export const EMBEDDED_EDIT_PAGE_LOAD_KEY = 'embedded-edit-page';
export const GLOBAL_PERMISSIONS_PAGE_LOAD_KEY = 'global-permissions';
export const INACCESSIBLE_PAGE_LOAD_KEY = 'inaccessible-page';
export const NOT_FOUND_LOAD_KEY = 'not-found';
export const SPACE_DIRECTORY_LOAD_KEY = 'space-directory';
export const SPACE_OVERVIEW_LOAD_KEY = 'space-overview';
export const SPACE_PAGES_LOAD_KEY = 'space-pages';
export const SPACE_BLOGS_LOAD_KEY = 'space-blogs';
export const TASKS_HOME_LOAD_KEY = 'tasks-home';
export const SPACE_MANAGER_LOAD_KEY = 'space-manager';
export const TEMPLATE_GALLERY_LOAD_KEY = 'template-gallery';
export const VIEW_PAGE_LOAD_KEY = 'view-page';
export const EMBEDDED_MARKETPLACE_LOAD_KEY = 'embedded-marketplace';
export const CREATE_CONTENT_LOAD_KEY = 'create-content';
export const PUBLIC_LINK_SHARED_PAGE_LOAD_KEY = 'public-link-shared-page';
export const PUBLIC_LINK_SITE_SETTING_PAGE_LOAD_KEY = 'public-link-site-setting-page';
export const PUBLIC_LINKS_SPACE_SETTINGS_PAGE_LOAD_KEY = 'public-links-space-settings-page';
export const WHITEBOARD_VIEW_PAGE_KEY = 'whiteboard-view-page';
export const DATABASE_VIEW_PAGE_KEY = 'database-view-page';
export const EMBED_VIEW_PAGE_LOAD_KEY = 'embed-view-page';
export const LIVE_PAGE_LOAD_KEY = 'live-page';

// this key is used to match the bm3 'live-page' experience with react UFO 'live-edit' page load
export const LIVE_PAGE_REACT_UFO_LOAD_KEY = 'live-edit';

export const TEAM_CALENDARS_LOAD_KEY = 'team-calendars';
export const ADMIN_TEAM_CALENDARS_LOAD_KEY = 'admin-team-calendars';
export const ADMIN_GENERAL_CONFIGURATION_LOAD_KEY = 'admin-general-configuration';
export const ADMIN_CONFIGURE_CODE_MACRO_LOAD_KEY = 'admin-configure-code-macro';
export const ADMIN_DEFAULT_SPACE_LOGO_CONFIG_LOAD_KEY = 'admin-default-space-logo-config';
export const ADMIN_HEADER_AND_FOOTER_LOAD_KEY = 'admin-header-and-footer';
export const ADMIN_SECURITY_CONFIGURATION_LOAD_KEY = 'admin-security-configuration';
export const ADMIN_TEMPLATES_AND_BLUEPRINTS_LOAD_KEY = 'admin-templates-and-blueprints';
export const ADMIN_IMPORT_SPACES_LOAD_KEY = 'admin-import-spaces';
export const ADMIN_TRASHED_SPACES_LOAD_KEY = 'admin-trashed-spaces';
export const ADMIN_APPLICATION_NAVIGATOR_KEY = 'admin-application-navigator';
export const ADMIN_THEMES_LOAD_KEY = 'admin-themes';
export const ADMIN_JIRA_MACRO_REPAIR_LOAD_KEY = 'admin-jira-macro-repair';
export const ADMIN_PDF_EXPORT_LOAD_KEY = 'admin-pdf-export';
export const ADMIN_MACRO_USAGE_LOAD_KEY = 'admin-macro-usage';
export const ADMIN_USER_ACCESS_KEY = 'admin-user-access';

// using same key as cc-analytics connect app, it continue to produce the same data
export const PAGE_ANALYTICS_LOAD_KEY = 'CONTENT_ANALYTICS';
export const SPACE_ANALYTICS_PAGE_LOAD_KEY = 'SPACE_ANALYTICS';
export const SITE_ANALYTICS_LOAD_KEY = 'INSTANCE_ANALYTICS';

export const SPACE_DETAILS_LOAD_KEY = 'space-details';
