import gql from 'graphql-tag';

export const RedactionsCountQuery = gql`
	query RedactionsCountQuery($contentId: ID!) {
		singleContent(id: $contentId) {
			id
			redactionMetadataCount
		}
	}
`;
