import { cfetch } from '@confluence/network';

export const postJson = (url: string, data: any) => {
	const fetchOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(data),
	};

	return cfetch(url, fetchOptions).then((response) => {
		return response.json();
	});
};

export const fetchJSON = (url: string) => {
	const fetchOptions = {
		method: 'GET',
		headers: {
			Accept: 'application/json',
		},
	};
	return cfetch(url, fetchOptions).then((response) => {
		return response.json();
	});
};
