import { useQuery } from '@apollo/react-hooks';

import { defaultPropertyValue, COVERPICTUREWIDTH } from './contentTopperConstants';
import { TitleContentPropertyQuery } from './TitleContentPropertyQuery.graphql';
import type {
	TitleContentPropertyQueryVariables,
	TitleContentPropertyQuery as TitleContentPropertyQueryType,
} from './__types__/TitleContentPropertyQuery';
import { contentPropertyHelper } from './contentPropertyHelper';

type useTitleContentPropertiesForPublishedPageProps = {
	contentId?: string;
};

export const getTitleContentProperty = (data: any) => {
	const titlePropertyContent = data?.content?.nodes?.[0]?.pageTitlePublished?.nodes?.find(
		(node: any) => node?.key == 'page-title-property-published',
	);
	const coverPictureWidth = (data?.content?.nodes?.[0]?.metadata?.frontend?.coverPictureWidth ||
		COVERPICTUREWIDTH.FULL) as COVERPICTUREWIDTH;

	if (titlePropertyContent?.value) {
		try {
			return {
				titleContentProperties: contentPropertyHelper(titlePropertyContent),
				coverPictureWidth,
			};
		} catch (e) {
			return { titleContentProperties: defaultPropertyValue, coverPictureWidth };
		}
	} else {
		return { titleContentProperties: defaultPropertyValue, coverPictureWidth };
	}
};

export const useTitleContentPropertiesForPublishedPage = ({
	contentId,
}: useTitleContentPropertiesForPublishedPageProps) => {
	const { data, error, loading } = useQuery<
		TitleContentPropertyQueryType,
		TitleContentPropertyQueryVariables
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		TitleContentPropertyQuery,
		{
			variables: {
				contentId,
				status: ['current', 'archived', 'draft'],
			},
			fetchPolicy: 'cache-first',
		},
	);

	if (!loading && data && !error) {
		const { titleContentProperties, coverPictureWidth } = getTitleContentProperty(data);

		return { titleContentProperties, coverPictureWidth };
	}

	return {
		titleContentProperties: defaultPropertyValue,
		coverPictureWidth: COVERPICTUREWIDTH.FULL,
	};
};
