import { UNSAFE_noExposureExp } from '@confluence/feature-experiments';

/**
 * Helper function to see if experiment is running, and if so, which cohort the tenant is in
 */
export const isConfluenceRedirectToLoomSDKExperimentEnabled = () => {
	// eslint-disable-next-line confluence-feature-gating/unsafe-no-exposure
	const [config] = UNSAFE_noExposureExp('loom_confluence_redirect_to_sdk_after_crossflow');
	const cohort = config.get('cohort', 'not-enrolled');
	const enabled = cohort === 'control' || cohort === 'variation';
	const isControlCohort = cohort === 'control';
	const isVariantCohort = cohort === 'variation';
	return { enabled, isControlCohort, isVariantCohort };
};
