interface WaitUntilOptions<T> {
	fn: () => Promise<T>;
	condition: (result: T) => boolean;
	retryWait: number;
	maxTries: number;
}

interface WaitUntilResult<T> {
	result: T;
	ok: boolean;
	tries: number;
}

// Poll a callback until a condition is met, up to a fixed amount of times.
export const waitUntil = async <T>({
	fn,
	condition,
	retryWait,
	maxTries,
}: WaitUntilOptions<T>): Promise<WaitUntilResult<T>> => {
	let tries = 0;
	// eslint-disable-next-line no-constant-condition
	while (true) {
		tries++;
		// eslint-disable-next-line no-await-in-loop
		const result = await fn();
		const ok = condition(result);
		if (tries === maxTries || ok) {
			return { ok, result, tries };
		}
		// eslint-disable-next-line no-await-in-loop
		await new Promise((resolve) => setTimeout(resolve, retryWait));
	}
};
