import { fg } from '@confluence/feature-gating';

import { useIsLoomBetaEnabled } from './useIsLoomBetaEnabled';
import { useLoomPermissionStatus } from './useLoomPermissionStatus';
import type { LoomEntryPointVariant } from './useLoomEntryPointVariantState';
import { useIsLoomDisabled } from './useIsLoomDisabled';

export const useLoomEntryPointVariant = (): LoomEntryPointVariant => {
	const isBetaEnabled = useIsLoomBetaEnabled();
	const isLoomDisabled = useIsLoomDisabled();

	// This can be replaced entirely by `isLoomDisabled` once Loom Beta is cleaned up
	const skipPermsCheck =
		isBetaEnabled || isLoomDisabled || !fg('confluence_frontend_loom_cross_sell');

	const loomEntryPointVariant = useLoomPermissionStatus({
		// If the entry point variant has been SSRd, use that result and skip the permissions check
		skip: skipPermsCheck || Boolean(window.__SSR_LOOM_ENTRY_POINT__),
	});

	// If the entry point already exists via SSR, return that value in the hook here (no need to check anything else)
	if (window.__SSR_LOOM_ENTRY_POINT__) {
		return window.__SSR_LOOM_ENTRY_POINT__;
	}

	if (isLoomDisabled) {
		return { entryPointVariant: 'DISABLED' };
	}

	if (isBetaEnabled) {
		return { entryPointVariant: 'BETA' };
	}

	// This should be moved into useIsLoomDisabled once Loom Beta is cleaned up.
	// For now, we have to do the Beta check first because `confluence_frontend_loom_cross_sell` is
	// not enabled on Beta sites
	if (!fg('confluence_frontend_loom_cross_sell')) {
		return { entryPointVariant: 'DISABLED' };
	}

	if (
		loomEntryPointVariant.entryPointVariant === 'CROSS_FLOW' &&
		fg('confluence_frontend_loom_cross_flow_ads_opt_out')
	) {
		return { entryPointVariant: 'DISABLED' };
	}

	return loomEntryPointVariant;
};
